import React, { useState, useEffect, useRef } from 'react'
import Moment from 'moment'
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

Moment.locale('en-gb');

const EnquiryForm = ({ config, type, hideLocation }) => {
    const parseDate = (date) => {

        if( date == null ) {
            return null;
        }
        let objDate = Moment();
        const dateParts = date.split('/');
        objDate.set('year', Number(dateParts[2]) );
        objDate.set('month', Number(dateParts[1])-1 );
        objDate.set('date', Number(dateParts[0]) );
        objDate.set('hour', 4 );
        objDate.set('minute', 0);
        objDate.set('second', 0 );
        objDate.set('millisecond', 0);

        return objDate;
    }

    const genPath = ( location ) => {
        return config.path.replace('*', location)
    }
    
    const [ location, setLocation ] = useState( config.defaultValues &&  config.defaultValues.location != undefined ? config.defaultValues.location : '' )
    const [ date, setDate ] = useState( config.defaultValues ? parseDate( config.defaultValues.date ) : null )
    const [ pax, setPax ] = useState( config.defaultValues ? config.defaultValues.pax : '' )
    //const [ url, setUrl ] = useState( config.defaultValues &&  config.defaultValues.location != undefined ? '/' + config.prefix + '/' + config.defaultValues.location : '/' + config.prefix )
    const [ url, setUrl ] = useState( genPath( config.defaultValues && config.defaultValues.location != undefined ? config.defaultValues.location : null ) )
    const [ actioned, setActioned ] = useState( false )
    const formRef = useRef()  
    
    const [ formData, setFormData ] = useState({
    	pax: config.defaultValues ? config.defaultValues.pax : '',
    	date: config.defaultValues ? parseDate( config.defaultValues.date ) : null,
    	location: config.defaultValues &&  config.defaultValues.location != undefined ? config.defaultValues.location : '',
    	actioned: false
    })
    

    
    const redirect = () => {
        history.pushState(undefined, 'Home', url);
        let url = '/' + location;
        if( config.prefix != undefined && config.prefix != null && config.prefix != '' ) {
            url = '/' + config.prefix + '/' + location;
        }
        window.location.replace(url);
    }
    
    
    useEffect(() => {     	
    	if( config.autoSubmit != undefined && config.autoSubmit && formData.actioned && isFormComplete() ) {
        	formRef.current.requestSubmit()
        }
                
	}, [formData])
    
    
    const isFormComplete = () => {
    	return ( formData.pax != '' && formData.pax > 0 ) && ( formData.location != '' && formData.location.length > 0 ) && ( formData.date != null ) ? true : false
    }
        
    return (
        <form className={`enquiry__form ${type}`} method="post" action={url} ref={formRef}>
            
           <div className="enquiry__form--date--pax">
				<PaxField 
					formData={formData}
					setFormData={setFormData}
				/>
	
				<DateField 
					date={date} 
					setDate={setDate}
					formData={formData}
					setFormData={setFormData}
				/>
            </div>

            { hideLocation && formData.location != undefined && formData.location != null && formData.location != '' ? (
            	<input type="hidden"  name="enquiry[location]" value={formData.location} />
            ) : (
				<LocationField
					config={config} 
					location={location} 
					setLocation={setLocation} 
					formData={formData}
					setFormData={setFormData}
					setUrl={setUrl} 
					genPath={genPath}
				/>
			)}
            
            <div className="enquiry__form--button">
				<button 
					className="button primary expanded"
					type="submit"
					disabled={!isFormComplete()}
				>Search
				</button>
            </div>
            <input name="utf8" type="hidden" value="&#x2713;" />
            <input name="authenticity_token" value={config.token} type="hidden" />
        </form>
    )
}

const PaxField = ({ formData, setFormData }) => {

    const paxList = [...Array(49).keys()].map(x => x+=1)
    paxList.push('50+')

    return (
        <div className="enquiry__form--pax">
            <select 
                name="enquiry[pax]" 
                value={formData.pax} 
                onChange={(e) => setFormData( {...formData, pax: e.target.value, actioned: true })}
            >
                <option value="" key="pax-0">Group Size</option>
                {paxList.map((p, index) => {
                    return (
                        <option key={`pax-` + p} value={p}>{p} people</option>
                    )
                })}
            </select>
        </div>
    )
}

const DateField = ({ formData, setFormData  }) => {

    const [ focused, setFocused ] = useState( false )
    const [ isMobile, setIsMobile ] = useState( false )
    
    useEffect(() => {
		setIsMobile( window.matchMedia("(max-width: 768px)").matches )
	}, []);
	    
    return (
        <div className="enquiry__form--date">
            <SingleDatePicker
                id="enquiry[date]"
                date={formData.date}
                onDateChange={date => setFormData({...formData, date: date, actioned: true })}
                onFocusChange={({ focused }) => {
                    setFocused( focused )
                }}
                focused={focused}
                showDefaultInputIcon={true}
                inputIconPosition="before"
                numberOfMonths={1}
                hideKeyboardShortcutsPanel={true}
                readOnly={true}
                displayFormat="DD/MM/YYYY"
                withFullScreenPortal={isMobile}
                disabled={false}
                keepOpenOnDateSelect={false}
            />
        </div>
    )
}

const LocationField = ({ config, formData, setFormData, setUrl, genPath }) => {
    
    const cityList = () => {
    	return (
    		<>
    		{config.locations.map((location, index) => (
    			<option value={location.slug} key={location.id}>{location.name}</option>
            ))}
            </>
    	)
    }
    
    
    const regionList = () => {
    	return (
    		<>
    		<optgroup label="UK cities">
    		{config.locations.uk.map((location, index) => (
    			<option value={location.slug} key={location.id}>{location.name}</option>
            ))}
            </optgroup>
            <optgroup label="European cities">
    		{config.locations.eu.map((location, index) => (
    			<option value={location.slug} key={location.id}>{location.name}</option>
            ))}
            </optgroup>
            </>
    	)
    }
    
    
    return (
        <div className="enquiry__form--location">
            <select 
                value={formData.location} 
                name="enquiry[location]" 
                onChange={(e) => {
                    setFormData({...formData, location: e.target.value, actioned: true })
                    setUrl( genPath( e.target.value  ) )
                }}
            >
                <option value="">Select City</option>
                
                {config.hasRegion ? regionList() : cityList()}
            </select>
        </div>
    )
}

export default EnquiryForm
