import React, {useState} from 'react'
import ReactDOM from 'react-dom'
import ReactModal from 'react-modal';
import EnquiryForm from 'components/product/grid2/enquiry-form'

ReactModal.setAppElement('body');

const ModalStyles = {
  overlay: {
	  backgroundColor: 'rgba(0, 0, 0, 0.75)',
	  zIndex: '1000'
  },
  content : {
	padding				  : '1rem',
	width				  : 'auto',
	maxWidth			  : '800px',
	height				  : 'auto',
	maxHeight             : '90%',
	top                   : '50%',
	left                  : '50%',
	right                 : 'auto',
	bottom                : 'auto',
	marginRight           : '-50%',
	overflow			  : 'unset',
	transform             : 'translate(-50%, -50%)'
  }
};


const SectionForm = ({config}) => {
	
	const [ selectedSection, setSelectedSection ] = useState( false );
	
	
	return (
		<>
			<div className="grid-x grid-padding-x second">
			{config.sections.map((section, index) => (
				<div className="cell small-6 medium-4 large-2" key={`section-${section.id}`}>
					<a onClick={() => setSelectedSection( section )}>
						<div className={`header-image ${section.sectionClass}`}>
							{section.trending && <span className="banner-tag red">Trending</span>}
						</div>
						<div className="align-content vertical center">
							<span className="header-image--cat">{section.name}</span>
							<span className="icon icon-right small right"></span>
						</div>
					</a>
				</div>
			))}
			</div>
			<ReactModal isOpen={selectedSection ? true : false} contentLabel="Login/Register" style={ModalStyles}>
				<button onClick={() => setSelectedSection( false )} className="close-button" aria-label="Close modal">
					<span aria-hidden="true">×</span>
				</button>
				<h2>Search for {selectedSection.name}</h2>
				{selectedSection ? (
				<EnquiryForm 
					config={{
						locations: selectedSection.locations,
						hasRegion: selectedSection.hasRegion,
						path: '/' + selectedSection.slug + '/*',
						defaultValues: config.defaultValues,
						autoSubmit: false,
						token: config.token
					}}
					type="plain"
					hideLocation={false}
				/>
				):null}
			</ReactModal>
		</>
	)
}

document.addEventListener('DOMContentLoaded', () => {

	const form = document.querySelector('#section-form');
	const config = JSON.parse( form.getAttribute('config') );

	ReactDOM.render(
		<SectionForm 
            config={config}
            type="white"
        />,
		form,
	)
});